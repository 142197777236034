<template>
    <div>
      <personnel_bar/>  
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="ข้อมูลผู้พ้นจากราชการ"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row no-gutters>
              <v-col cols="12" md="8" class="text-right">
                <v-row no-gutters>
                  <v-col cols="12" class="pa-2">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                      single-line
                      hide-details
                      v-on:keyup.enter="OnEnter()"
                      filled
                      class="mb-2"
                    />
                    <v-radio-group row>
                      <v-radio
                        label="ชื่อ"
                        value="radio-1"
                        @click.native="search_name()"
                      ></v-radio>
                      <v-radio
                        label="นามสกุล"
                        value="radio-2"
                        @click.native="search_lastname()"
                      ></v-radio>
                      <v-radio
                        label="รหัสบัตรประชาชน"
                        value="radio-3"
                        @click.native="search_idcard()"
                      ></v-radio>
  
                      <v-radio
                        label="ปีที่เกษียณ"
                        value="radio-5"
                        @click.native="search_yearretire()"
                      ></v-radio>
                      <v-radio
                        label="แสดงทั้งหมด"
                        value="radio-6"
                        @click.native="personnel_temporary_delQueryAll()"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
  
                  <v-col cols="4" class="pa-2">
                    <v-select
                      v-model="rangname_search"
                      outlined
                      :items="rang_names"
                      item-text="text"
                      item-value="value"
                      label="วิทยฐานะ"
                      @change="search_rang()"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4" class="pa-2">
                    <v-select
                      v-model="position_search"
                      outlined
                      :items="position_select"
                      item-text="text"
                      item-value="value"
                      label="ตำแหน่ง"
                      @change="search_position()"
                    >
                    </v-select>
                  </v-col>
  
                  <v-col cols="4" class="pa-2">
                    <v-autocomplete
                      v-model="college_search"
                      outlined
                      :items="colleges"
                      item-text="college_name"
                      item-value="college_code"
                      label="สถานศึกษา"
                      @change="search_college()"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4" class="text-right pa-2" >
                <v-btn block color="info" large @click="clearSearch()">
                  <v-icon>mdi-format-clear</v-icon>
                  Clear Search
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
  
          <v-data-table
            :headers="headers"
            :items="personnel_temporary_dels"
            class="elevation-1"
            :loading="loading"
            :search="search"
          >
          
          <template v-slot:item.ptdel_status_del="{ item }">
            <v-chip color="info">
                   <span v-if="item.ptdel_status_del==='resign'">ลาออก</span>
             <span v-if="item.ptdel_status_del==='discard'">ให้ออก</span>
             <span v-if="item.ptdel_status_del==='dismiss'">ไล่ออก</span>
             <span v-if="item.ptdel_status_del==='transfer'">โอนย้าย</span>
             <span v-if="item.ptdel_status_del==='retire'">เกษียณ</span>
             <span v-if="item.ptdel_status_del==='died'">เสียชีวิต</span> 
             <span v-if="item.ptdel_status_del==='disclaim'">สละสิทธิ์ไม่รายงานตัว</span> 
            </v-chip>
         
      
            </template>
  
          
            
            <template v-slot:item.ptdel_datetime_outof="{ item }">
              {{
                item.ptdel_datetime_outof  | moment("add", "543 years") | moment("D MMMM YYYY")
              }}
            </template>
            <template v-slot:item.brith_day="{ item }">
              {{
                item.brith_day + "/" + item.brith_month + "/" + item.brith_year
              }}
            </template>
            <template v-slot:item.appoin_day="{ item }">
              {{
                item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
              }}
            </template>
  
           
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="warning"
                @click.stop="personnel_temporary_delEdit(item.ptdel_id_card)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.dels="{ item }">
              <v-icon
                color="red"
                @click.stop="personnel_temporary_delDelete(item.ptdel_id_card)"
              >
                mdi-delete
              </v-icon>
            </template>
  
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </base-material-card>
  
  
  
 
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>
  
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import personnel_bar from "../../components/admin/personnel_bar.vue";

  export default {
    components: { personnel_bar },
    data() {
      return {
        loading: false,
        ApiKey: "HRvec2021",
        valid: true,
        addpersonnel_temporary_deldialog: false,
        editpersonnel_temporary_deldialog: false,
        deletepersonnel_temporary_deldialog: false,
        csvpersonnel_temporary_deldialog: false,
        csvpersonnel_temporary_delOutofdialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        personnel_temporary_dels: [],
        addpersonnel_temporary_del: {},
        editpersonnel_temporary_del: {},
        search: "",
        pagination: {},
        headers: [    
          {
            text: "วิทยาลัย",        
            align: "left",
            value: "college_name"
          },
          { text: "รหัสบัตรประชาชน", align: "center", value: "ptdel_id_card" },
          { text: "ชื่อ-นามสกุล", align: "center", value: "personnelNAME" },      
          { text: "ตำแหน่ง", align: "center", value: "ptdel_position_name" },
          { text: "เลขที่ตำแหน่ง", align: "center", value: "ptdel_id_position" },
          { text: "วิทยฐานะ", align: "center", value: "ptdel_rang_name" },
          { text: "เนื่องจาก", align: "center", value: "ptdel_status_del" },
  
          { text: "ปีที่", align: "center", value: "ptdel_retire_year" },       
          { text: "มีผลวันที่", align: "center", value: "ptdel_datetime_outof" },       
 
        ],
        rang_names: [
          { text: "-", value: "-" },
          { text: "ต้น", value: "ต้น" },
          { text: "ปฏิบัติงาน", value: "ปฏิบัติงาน" },
          { text: "ปฏิบัติการ", value: "ปฏิบัติการ" },
          { text: "ชำนาญงาน", value: "ชำนาญงาน" },
          { text: "ชำนาญการ", value: "ชำนาญการ" },
          { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
          { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
          { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
        ],
        position_select: [
          { text: "ผู้อำนวยการ", value: "ผู้อำนวยการ" },
          { text: "รองผู้อำนวยการ", value: "รองผู้อำนวยการ" },
          { text: "ครู", value: "ครู" },
          { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
          { text: "ศึกษานิเทศก์", value: "ศึกษานิเทศก์" },
          { text: "เจ้าพนักงานธุรการ", value: "เจ้าพนักงานธุรการ" },
          {
            text: "เจ้าพนักงานการเงินและบัญชี",
            value: "เจ้าพนักงานการเงินและบัญชี"
          },
          { text: "นักวิเคราะห์นโยบายและแผน", value: "นักวิเคราะห์นโยบายและแผน" },
          { text: "เจ้าพนักงานพัสดุ", value: "เจ้าพนักงานพัสดุ" }
        ],
  
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
  
        college: {},
        provinces: [],
        prefectures: [],
        userstatus: [],
        man_powers: [],
        colleges: [],
        personnel_temporary_delstatus: [],
        regions: [],
        region_ena: true,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menu: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu6: false,
        menu7: false,
        data_syslog: {},
        college_search: {},
        position_search: {},
        rangname_search: {},
        upcsv_file: {},
        file2: null,
        validateInputcheck: [],
        prefixs: []
      };
    },
  
    async mounted() {
        await this.sweetAlertLoading();
      await this.personnel_temporary_delQueryAll();
    
  
    let resultcty = await this.$http.post("collegetype.php", {
        ApiKey: this.ApiKey
      });
      this.collegetypes = resultcty.data;

    let resultcl = await this.$http.post("college.php", {
        ApiKey: this.ApiKey
      });
  
      this.colleges = resultcl.data;     

    let resultpv = await this.$http.post("province.php", {
        ApiKey: this.ApiKey
      });
      this.provinces = resultpv.data;
  
     let resultrg = await this.$http.post("region.php", {
        ApiKey: this.ApiKey
      });
      this.regions = resultrg.data;
  
     let user_status_result = await this.$http.post("user_status.php", {
        ApiKey: this.ApiKey
      });
      this.userstatus = user_status_result.data;
  
      let prefix = await this.$http.post("prefix.php", { ApiKey: this.ApiKey });
      this.prefixs = prefix.data;
  
      Swal.close();
    },
  
    methods: {
        async search_position(){
            await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary_del.php", {
          ApiKey: this.ApiKey,
          ptdel_position_name: this.position_search
        });
        this.personnel_temporary_dels = result.data;
        Swal.close();
        },
      async clearSearch() {
        this.college_search = "";
        this.position_search = "";
        this.rangname_search = "";
      },  
   
      async sweetAlertLoading() {
        Swal.fire({
          title: "Please Wait !",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            
          },
          onBeforeOpen: () => {
            Swal.showLoading();
            
          },
          onAfterClose: () => {
            Swal.hideLoading();
            
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      },
  
      async search_name() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary_del.php", {
          ApiKey: this.ApiKey,
          ptdel_frist_name: this.search
        });
        this.personnel_temporary_dels = result.data;
        Swal.close();
      },
  
      async search_lastname() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary_del.php", {
          ApiKey: this.ApiKey,
          ptdel_last_name: this.search
        });
        this.personnel_temporary_dels = result.data;
        Swal.close();
      },
  
      async search_idcard() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary_del.php", {
          ApiKey: this.ApiKey,
          ptdel_id_cards: this.search
        });
        this.personnel_temporary_dels = result.data;
        Swal.close();
      },
  
      async search_college() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary_del.php", {
            ApiKey: this.ApiKey,
            ptdel_college_code: this.college_search
          })
          .finally(() => (this.loading = false));
        this.personnel_temporary_dels = result.data;
        Swal.close();
      },
  
   
      async search_rang() {
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary_del.php", {
            ApiKey: this.ApiKey,
            ptdel_rang_name: this.rangname_search
          })
          .finally(() => (this.loading = false));
        this.personnel_temporary_dels = result.data;
      },
      async search_yearretire() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary_del.php", {
            ApiKey: this.ApiKey,
            ptdel_appoin_year: this.search
          })
          .finally(() => (this.loading = false));
        this.personnel_temporary_dels = result.data;
        Swal.close();
      },
  
  
      async man_powerQuery() {
        let man_power_result;
        man_power_result = await this.$http.post("jor18.php", {
          ApiKey: this.ApiKey,
          j18college_code: this.addpersonnel_temporary_del.college_code
        });
        this.man_powers = man_power_result.data;
      },
  
      async personnel_temporary_delQueryAll() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary_del.php", {
            ApiKey: this.ApiKey
          })
          .finally(() => (this.loading = false));
        this.personnel_temporary_dels = result.data;
        Swal.close();
      },
  
      async searchWait() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary_del.php", {
            ApiKey: this.ApiKey,
            status_appove: "wait"
          })
          .finally(() => (this.loading = false));
        this.personnel_temporary_dels = result.data;
        Swal.close();
      },
  
      async personnel_temporary_delAdd() {
        this.addpersonnel_temporary_del = {};
        this.addpersonnel_temporary_deldialog = true;
      },
  
      async personnel_temporary_delCSV() {
        this.csvpersonnel_temporary_deldialog = true;
      },
  
      async personnel_temporary_delOutofCSV() {
        this.csvpersonnel_temporary_delOutofdialog = true;
      },
  
      async addpersonnel_temporary_delSubmit() {
        if (this.$refs.addpersonnel_temporary_delform.validate()) {
          this.addpersonnel_temporary_del.ApiKey = this.ApiKey;
          this.addpersonnel_temporary_del.p_word =
            this.addpersonnel_temporary_del.brith_day +
            "/" +
            this.addpersonnel_temporary_del.brith_month +
            "/" +
            this.addpersonnel_temporary_del.brith_year;
          let result = await this.$http.post(
            "personnel_temporary_del.insert.php",
            this.addpersonnel_temporary_del
          );
         
          if (result.data.status == true) {
            this.personnel_temporary_del = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "insert";
            this.data_syslog.page_log = "personnel_temporary_del";
            this.data_syslog.table_log = "personnel_temporary_del";
            this.data_syslog.detail_log = this.personnel_temporary_del.ptdel_id_card;
            this.data_syslog.date_times = this.date_today_log;
            this.personnel_temporary_delQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "บันทึกข้อมูลผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporary_delQueryAll();
          }
          this.addpersonnel_temporary_deldialog = false;
        }
      },
      async personnel_temporary_delEdit(ptdel_id_card) {
        let result = await this.$http.post("personnel_temporary_del.php", {
          ApiKey: this.ApiKey,
          ptdel_id_card: ptdel_id_card
        });
        this.editpersonnel_temporary_del = result.data;
        this.editpersonnel_temporary_del.p_word = "";
        let man_power_result;
        man_power_result = await this.$http.post("jor18.php", {
          ApiKey: this.ApiKey,
          j18college_code: this.editpersonnel_temporary_del.college_code
        });
        this.man_powers = man_power_result.data;
        this.validateInput();
        this.editpersonnel_temporary_deldialog = true;
      },
  
      async editpersonnel_temporary_delSubmit() {
        if (this.$refs.editpersonnel_temporary_delform.validate()) {
          this.editpersonnel_temporary_del.ApiKey = this.ApiKey;
          if (this.editpersonnel_temporary_del.p_word == "")
            delete this.editpersonnel_temporary_del.p_word;
  
          if (this.editpersonnel_temporary_del.rang_condition == null)
            this.editpersonnel_temporary_del.rang_condition = "0";
  
  
          let result = await this.$http.post(
            "personnel_temporary_del.update.admin.php",
            this.editpersonnel_temporary_del
          );
          if (result.data.status == true) {
            this.personnel_temporary_del = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporary_delQueryAll();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "update";
            this.data_syslog.page_log = "personnel_temporary_del";
            this.data_syslog.table_log = "personnel_temporary_del";
            this.data_syslog.detail_log = this.personnel_temporary_del.ptdel_id_card;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.editpersonnel_temporary_deldialog = false;
        }
      },
      async personnel_temporary_delDelete(ptdel_id_card) {
        let result = await this.$http.post("personnel_temporary_del.php", {
          ApiKey: this.ApiKey,
          ptdel_id_card: ptdel_id_card
        });
        this.editpersonnel_temporary_del = result.data;
        this.deletepersonnel_temporary_deldialog = true;
      },
  
     
   
    },
    computed: {
      time_stamp() {
        const d = new Date();
        let time = d.getTime();
        return time;
      },
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
          date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  